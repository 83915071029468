/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import { graphql } from "gatsby"

import Layout from "../components/Layout/"
import Listing from "../components/Listing/"

const Category = ({
  pageContext: { category },
  data: {
    toggleState,
    posts: { nodes },
  },
}) => (
  <Layout>
    <Styled.h2 sx={{ mt: 0, pb: 4 }}>{category}</Styled.h2>
    <Listing posts={nodes} toggleState={toggleState} />
  </Layout>
)

export default Category

export const pageQuery = graphql`
  query CategoryPage($category: String!) {
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: {
            elemMatch: {
              category: {
                document: { elemMatch: { data: { name: { eq: $category } } } }
              }
            }
          }
        }
      }
    ) {
      totalCount
      nodes {
        uid
        data {
          date
          thumbnail {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title {
            html
            text
          }
          quote {
            text
          }
          content {
            html
            text
          }
          categories {
            category {
              document {
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
